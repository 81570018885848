export default function getPlanQuery(planSelected) {
  let planQuery = planSelected.code.toLowerCase();

  const { frequency } = planSelected;

  if (frequency === 'yearly') planQuery += '_anual';
  if (frequency === 'installment') planQuery += '_anual_installment';

  return planQuery;
}
