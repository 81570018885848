/* eslint-disable consistent-return */
import { Modal } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';

import { plansLevels } from '../../_constants/plansLevels';
import getPlanQuery from '../../_utils/getPlanQuery';
import Button from '../../../../_components/Button';
import { useAuth } from '../../../../../_legacy/containers/AuthContext/AuthContext';
import { useChoosePlan } from '../../../../../_legacy/containers/NewPaymentPage/contexts/ChoosePlanContext';
import { subscribe } from '../../../../../_legacy/services/api/adapters/gateway';
import { modalTexts } from './modalTexts';
import ReasonsOptions from './ReasonsOptions';
import {
  Footer, ModalBody, ModalContent, ModalTitle
} from './styles';
import VoucherField from './VoucherField';

export default function ChangePlanModal({ open, onClose, specialPromo }) {
  const { planSelected, setPlanSelected, plansList } = useChoosePlan();
  const { subscription: { planName, cycle }, isTrial, isFreemium } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [justificationOpt, setJustificationOpt] = useState();
  const [justificationTxt, setJustificationTxt] = useState('');
  const [clientSuspensionReason, setClientSuspensionReason] = useState(null);
  const [voucher, setVoucher] = useState('');
  const [voucherValid, setVoucherValid] = useState(false);
  const [actionType, setActionType] = useState(null);

  const getActionType = () => {
    const oldLevel = plansLevels[planName];
    const newLevel = plansLevels[planSelected.code];

    const monthlyToAnnual = cycle === 'MONTHLY' && ['annual', 'yearly', 'installment'].includes(planSelected.frequency);
    const annualToMonthly = cycle === 'YEARLY' && planSelected.frequency === 'monthly';

    const isFree = ['Trial', 'Freemium'].includes(planSelected.code);

    if (oldLevel === 0 && newLevel === 0) return 'trialToFreemium';
    if (isFree) return 'downsellFree';
    if ((monthlyToAnnual && oldLevel === newLevel)) return 'monthlyToAnnual';
    if ((annualToMonthly && oldLevel === newLevel)) return 'annualToMonthly';
    if (oldLevel < newLevel) return 'upsell';
    return 'downsell';
  };

  const updateToAdvanced = () => {
    window.location.href = 'https://contraktor.com.br/fale-com-vendas/';
  };

  const submit = useCallback(async () => {
    setLoading(true);

    try {
      if (planSelected.code === 'Advanced') return updateToAdvanced();
      if (planSelected.code === 'Freemium') {
        return await subscribe({
          planName: 'Freemium',
          voucher: null,
          clientSuspensionReason,
          isInstallment: false
        });
      }

      const planQuery = getPlanQuery(planSelected);
      const sp = plansList.find((p) => p.param === planQuery) || plansList[1];

      if (isTrial || isFreemium) return setTimeout(() => history.push(`/subscription/payment?selectedPlan=${planQuery}`), 300);

      const isInstallment = ['annual', 'yearly', 'installment'].includes(sp.frequency);

      const normalizedFrequencies = {
        installment: 'YEARLY',
        annual: 'YEARLY',
        yearly: 'YEARLY',
        monthly: 'MONTHLY'
      };

      const payload = {
        planName: sp.planName,
        frequency: normalizedFrequencies[sp.frequency.toLowerCase()],
        voucher: voucher === '' ? null : voucher,
        isInstallment,
        clientSuspensionReason
      };

      await subscribe(payload);
      setPlanSelected(sp);
      toast.success(`Boas-vindas ao plano ${sp.code}!`);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
      onClose();
    }
  }, [
    planSelected,
    voucher,
    clientSuspensionReason,
    onClose,
    history,
    isFreemium,
    isTrial,
    plansList,
    setPlanSelected
  ]);

  useEffect(() => {
    if (specialPromo && planSelected.frequency === 'installment') {
      setVoucher('FINAL_DE_ANO_CK_25');
      setVoucherValid(true);
    } else if (specialPromo) {
      setVoucher('FINAL_DE_ANO_CK_35');
      setVoucherValid(true);
    } else {
      setVoucher('');
    }
  }, [specialPromo, planSelected.frequency]);

  useEffect(() => {
    setActionType(getActionType());
  }, [planSelected]); //eslint-disable-line

  useEffect(() => {
    if (justificationOpt) {
      const emptyVals = [undefined, null, ''];
      const hasTextJustification = !emptyVals.includes(justificationTxt);
      const reason = !hasTextJustification ? justificationOpt.value : `${justificationOpt.value} + ${justificationTxt}`;

      setClientSuspensionReason(reason);
    }
  }, [justificationOpt, justificationTxt, setClientSuspensionReason]);

  return actionType && (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        <ModalTitle>
          {modalTexts[actionType].title}
        </ModalTitle>
        <ModalBody>
          {modalTexts[actionType].body}
        </ModalBody>
        {
          modalTexts[actionType].justify && (
            <ReasonsOptions
              onChange={(val) => setJustificationOpt(val)}
              optionSelected={justificationOpt}
              reason={justificationTxt}
              onReasonChange={(val) => setJustificationTxt(val)}
            />
          )
        }
        {
          modalTexts[actionType].voucher && !isFreemium && !isTrial && (
            <VoucherField
              specialPromo={specialPromo}
              onChange={(val) => setVoucher(val)}
              frequency={planSelected.frequency}
              onValidate={(val) => {
                setVoucherValid(val);
              }}
            />
          )
        }
        <Footer>
          <Button
            onClick={onClose}
            label="Voltar"
            theme="secondary"
          />
          <Button
            loading={loading}
            disabled={
              (modalTexts[actionType].justify && !clientSuspensionReason)
              || (modalTexts[actionType].voucher && voucher.length && !voucherValid)
            }
            onClick={submit}
            label="Confirmar contratação"
            theme="primary"
          />
        </Footer>
      </ModalContent>
    </Modal>
  );
}
