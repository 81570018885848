/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import currency from 'currency.js';
import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback
} from 'react';

import { getPlanPrice } from '../../../../utils/getPlanPrice';
import { useAuth } from '../../../AuthContext/AuthContext';

export const ChoosePlanContext = createContext({});

const initialPlan = {
  name: '',
  price: '',
  features: []
};

const features = {
  light: [
    'Envie até 15 documentos por mês',
    'Acompanhe o status de seus documentos',
    '500mb de armazenamento em nuvem',
    'Treinamento por e-mail e vídeos',
    'Suporte por e-mail',
    '6 usuários',
    'Assinantes ilimitados'
  ],
  essential: [
    'Envie até 50 documentos por mês',
    'Acompanhe o status de seus documentos',
    '1Gb de armazenamento em nuvem',
    'Treinamento por e-mail e vídeos',
    'Suporte por e-mail',
    '10 usuários',
    'Assinantes ilimitados'
  ],
  unlimited: [
    'Envie documentos ilimitados',
    'Acompanhe o status de seus documentos',
    '2Gb de armazenamento em nuvem',
    'Treinamento por e-mail e vídeos',
    'Suporte por e-mail',
    '12 usuários',
    'Assinantes ilimitados',
    'Logo personalizado nos seus e-mails'
  ]
};

const freemiumData = {
  name: 'Free',
  planName: 'Freemium',
  frequency: null,
  features: [
    'Envie até 5 documentos por mês',
    'Upload máximo de documentos de 5mb',
    '15 dias para assinar documentos',
    '1 Usuário',
    '30 dias de armazenamento após criar cada documento'
  ]
};

const trialData = {
  name: 'Trial',
  planName: 'Trial',
  frequency: 'WEEKLY',
  features: [
    'Envie documentos ilimitados',
    'Acompanhe o status de seus documentos',
    'Armazene seus documentos na nuvem',
    'Treinamento por e-mail e vídeos',
    'Suporte por e-mail',
    'Até 10 usuários',
    'Assinantes ilimitados'
  ]
};

export const ChoosePlanProvider = ({ children }) => {
  const currentPlanSelected = localStorage.getItem('planSelected');
  const [planSelected, setPlanSelected] = useState(JSON.parse(currentPlanSelected) || initialPlan);
  const {
    user, isTrial, subscription
  } = useAuth();
  const additionalFeatures = JSON.parse(localStorage.getItem('additionalFeatures'));
  const [whatsappActive, whatsappQuota] = [
    additionalFeatures?.some((af) => af?.name === 'whatsapp'),
    additionalFeatures?.find((af) => af?.name === 'whatsapp').quota
  ];

  const formatPrice = (price) => {
    const formatted = currency(price, { separator: ',', decimal: ',' }).format();

    return formatted;
  };

  const getPriceDescription = (price, planId, format, isPaidPlan, voucherDiscount) => {
    const planPrice = getPlanPrice(whatsappActive, whatsappQuota, price, planId, format, isPaidPlan, voucherDiscount);
    const suffix = format === 'MONTHLY' ? ' /mês' : ' /ano';

    return `R${formatPrice(planPrice)}${suffix}`;
  };

  const plansList = [
    {
      name: 'Light Mensal',
      planName: 'Light',
      paymentDescription: 'Mensal',
      suffix: '/mês',
      param: 'light',
      frequency: 'MONTHLY',
      price: '33,90',
      priceFloat: 33.90,
      priceDescription: getPriceDescription(33.90, 'Light', 'MONTHLY', true),
      priceMonthlyDescription: getPriceDescription(33.90, 'Light', 'MONTHLY', true),
      hasDiscount: false,
      discountPercentage: 0,
      features: features.light
    },
    {
      name: 'Light Anual Parcelado',
      planName: 'Light',
      paymentDescription: 'Anual parcelados',
      suffix: '/ano',
      paymentDisclaimer: '(por 12 meses)',
      param: 'light_anual_installment',
      frequency: 'ANNUAL',
      price: '358,80',
      priceFloat: 358.80,
      priceDescription: getPriceDescription(29.90, 'Light', 'MONTHLY', true),
      priceMonthlyDescription: getPriceDescription(29.90, 'Light', 'MONTHLY', true),
      hasDiscount: true,
      discountPercentage: 12,
      features: features.light
    },
    {
      name: 'Light Anual à vista',
      planName: 'Light',
      paymentDescription: 'Anual à vista',
      suffix: '/ano',
      paymentDisclaimer: '(R$26,90/mês)',
      param: 'light_anual',
      frequency: 'ANNUAL',
      price: '322,92',
      priceFloat: 322.92,
      priceDescription: getPriceDescription(322.92, 'Light', 'ANNUAL', true),
      priceMonthlyDescription: getPriceDescription(26.90, 'Light', 'MONTHLY', true),
      hasDiscount: true,
      discountPercentage: 21,
      features: features.light
    },
    {
      name: 'Essential Mensal',
      planName: 'Essential',
      paymentDescription: 'Mensal',
      suffix: '/mês',
      param: 'essential',
      frequency: 'MONTHLY',
      price: '49,90',
      priceFloat: 49.90,
      priceDescription: getPriceDescription(49.90, 'Essential', 'MONTHLY', true),
      priceMonthlyDescription: getPriceDescription(49.90, 'Essential', 'MONTHLY', true),
      hasDiscount: false,
      discountPercentage: 0,
      features: features.essential
    },
    {
      name: 'Essential Anual Parcelado',
      planName: 'Essential',
      paymentDescription: 'Anual parcelado',
      suffix: '/ano',
      paymentDisclaimer: '(por 12 meses)',
      param: 'essential_anual_installment',
      frequency: 'ANNUAL',
      price: '568,80',
      priceFloat: 568.80,
      priceDescription: getPriceDescription(47.40, 'Essential', 'MONTHLY', true),
      priceMonthlyDescription: getPriceDescription(47.40, 'Essential', 'MONTHLY', true),
      hasDiscount: true,
      discountPercentage: 12,
      features: features.essential
    },
    {
      name: 'Essential Anual à vista',
      planName: 'Essential',
      paymentDescription: 'Anual à vista',
      suffix: '/ano',
      paymentDisclaimer: '(R$44,41/mês)',
      param: 'essential_anual',
      frequency: 'ANNUAL',
      price: '538,92',
      priceFloat: 538.92,
      priceDescription: getPriceDescription(538.92, 'Essential', 'ANNUAL', true),
      priceMonthlyDescription: getPriceDescription(44.91, 'Essential', 'MONTHLY', true),
      hasDiscount: true,
      discountPercentage: 21,
      features: features.essential
    },
    {
      name: 'Unlimited Mensal',
      planName: 'Unlimited',
      paymentDescription: 'Mensal',
      param: 'unlimited',
      frequency: 'MONTHLY',
      price: '149,90',
      priceFloat: 149.90,
      priceDescription: getPriceDescription(149.90, 'Unlimited', 'MONTHLY', true),
      priceMonthlyDescription: getPriceDescription(149.90, 'Unlimited', 'MONTHLY', true),
      hasDiscount: false,
      discountPercentage: 0,
      features: features.unlimited
    },
    {
      name: 'Unlimited Anual Parcelado',
      planName: 'Unlimited',
      paymentDescription: 'Anual parcelado',
      paymentDisclaimer: '(por 12 meses)',
      param: 'unlimited_anual_installment',
      frequency: 'ANNUAL',
      price: '1.708,92',
      priceFloat: 1708.92,
      priceDescription: getPriceDescription(142.41, 'Unlimited', 'MONTHLY', true),
      priceMonthlyDescription: getPriceDescription(142.41, 'Unlimited', 'MONTHLY', true),
      hasDiscount: true,
      discountPercentage: 5,
      features: features.unlimited
    },
    {
      name: 'Unlimited Anual à vista',
      planName: 'Unlimited',
      paymentDescription: 'Anual à vista',
      paymentDisclaimer: '(R$134,90/mês)',
      param: 'starter_anual',
      frequency: 'ANNUAL',
      price: '1.618,80',
      priceFloat: 1618.8,
      priceDescription: getPriceDescription(1618.80, 'Unlimited', 'ANNUAL', true),
      priceMonthlyDescription: getPriceDescription(134.90, 'Unlimited', 'MONTHLY', true),
      hasDiscount: true,
      discountPercentage: 10,
      features: features.unlimited
    }
  ];

  const monthlyPlans = plansList
    .filter((p) => !p.hasDiscount)
    .sort((a, b) => {
      if (a.priceFloat < b.priceFloat) {
        return -1;
      }
      if (a.priceFloat > b.priceFloat) {
        return 1;
      }
      return 0;
    });

  const onSelectedPlan = useCallback((plan) => {
    setPlanSelected(plan);
    localStorage.setItem('planSelected', JSON.stringify(plan));
  }, []);

  const getCurrentPlan = user?.organization && plansList
    .find((plan) => (plan.planName === subscription?.planName && plan.name.includes('Mensal')));

  const currentPlanInfo = () => {
    if (user?.organization && subscription?.planName === 'Freemium') return freemiumData;
    if (user?.organization && subscription?.planName === 'Trial') return trialData;

    if (subscription) {
      const isAnnual = subscription.cycle === 'YEARLY' && subscription.providerId?.includes('sub_');
      const isInstallment = !subscription.providerId?.includes('sub_');

      if (isAnnual) return plansList.find((plan) => (plan.planName === subscription.planName && plan.frequency === 'ANNUAL') && !plan.param.includes('installment'));
      if (isInstallment) return plansList.find((plan) => plan.planName === subscription.planName && plan.param.includes('installment'));

      return getCurrentPlan;
    }

    return trialData;
  };

  return (
    <ChoosePlanContext.Provider
      value={{
        planSelected,
        setPlanSelected: onSelectedPlan,
        plansList,
        monthlyPlans,
        isTrial,
        currentPlanInfo: currentPlanInfo(),
        getPriceDescription
      }}
    >
      {children}
    </ChoosePlanContext.Provider>
  );
};

export const useChoosePlan = () => {
  const context = useContext(ChoosePlanContext);

  if (!context) {
    throw new Error('useChoosePlan must be used within a ChoosePlanProvider');
  }

  return context;
};

ChoosePlanProvider.propTypes = {
  children: PropTypes.node.isRequired
};
