import { whatsappQuotaPrices } from '../../_refactor/pages/Plans/_components/WhatsappCard/constants/whatsappQuotaPrices';

export const getPlanPrice = (
  whatsappActive,
  whatsappQuota,
  price,
  id,
  format,
  isPaidPlan,
  voucherDiscount
) => {
  const priceVoucherDisc = (price * (voucherDiscount / 100));

  if ((whatsappActive && whatsappQuota && isPaidPlan)) {
    const isAnnual = ['ANNUAL', 'INSTALLMENT'].includes(format);
    const starterDefaultDiscount = 7.50;

    const quotaPrice = id === 'Unlimited'
      ? whatsappQuotaPrices[parseInt(whatsappQuota, 10)] - starterDefaultDiscount - priceVoucherDisc
      : whatsappQuotaPrices[parseInt(whatsappQuota, 10)] - priceVoucherDisc;

    const additionalValue = isAnnual ? quotaPrice * 12 : quotaPrice;

    return price + additionalValue;
  }

  if (voucherDiscount > 0) return price - priceVoucherDisc;

  return price;
};
